import $ from 'jquery';
import 'slick-carousel';

$(document).ready(function() {

	$('.slider_banner').slick({
		arrows: false,
		dots: true,
		infinite: true,
		speed: 300
	});
	
	$('.slider_category').slick({
		arrows: true,
		dots: false,
		infinite: false,
		speed: 300,
		slidesToShow: 4,
		slidesToScroll: 4,
		responsive: [
		{
  		breakpoint: 1024,
  		settings: {
			arrows: true,
			dots: false,
			slidesToShow: 2,
			slidesToScroll: 2,
			infinite: true
  		}
		},
		{
  		breakpoint: 600,
  		settings: {
			arrows: true,
			dots: false,
			slidesToShow: 2,
			slidesToScroll: 2
  		}
		},
		{
  		breakpoint: 480,
  		settings: {
			arrows: true,
			dots: false,
			slidesToShow: 1,
			slidesToScroll: 1
  		}
		}
		]
	});

});